import { Suspense } from "react";
import ScrollToTop from "../helpers/scroll-top";

function withSuspense(Component) {
  return (
    <ScrollToTop>
      <Suspense
        fallback={
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        }
      >
        <Component />
      </Suspense>
    </ScrollToTop>
  );
}

export default withSuspense;
