export const colors = {
  primary: "#3b82f6",
  danger: "#ef4444",
  success: "#10b981",
  warning: "#f59e0b",
  info: "#3b82f6",
  dark: "#374151",
  light: "#f9fafb",
  white: "#ffffff",
  black: "#000000",
};
