import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import HeroSliderOne from "../../wrappers/hero-slider/HeroSliderOne";
import FeatureIcon from "../../wrappers/feature-icon/FeatureIcon";
import TabProduct from "../../wrappers/product/TabProduct";
import { store } from "../../store/store";
import { setProducts } from "../../store/slices/product-slice";
import products from "../../data/products.json";

const Home = () => {
  return (
    <>
      <SEO
        titleTemplate="Design Your World"
        description="Fashion home of flone react minimalist eCommerce template."
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* hero slider */}
        <HeroSliderOne />

        {/* featured icon */}
        <FeatureIcon spaceTopClass="pt-100" spaceBottomClass="pb-60" />

        {/* tab product */}
        <TabProduct spaceBottomClass="pb-60" category="fashion" />
      </LayoutOne>
    </>
  );
};

export async function homeLoader() {
  store.dispatch(setProducts(products));
  return null;
}

export default Home;
