import apiService from "../services/apiService";

export const getAllProducts = (page = 0, size = 10) =>
  apiService.get(`/api/admin/products/list?page=${page}&size=${size}`);

export const getProductById = (id) => {
  if (!id || id === "new") {
    return Promise.reject(new Error("Invalid product ID"));
  }
  return apiService.get(`/api/admin/products/${id}`);
};

export const createProduct = (productData) => {
  const transformedData = {
    productName: productData.productName,
    productType: productData.productType,
    description: productData.description,
    additionalInfo: productData.additionalInfo,
    categoryId: productData.categoryId,
    variants: productData.variants.map((variant) => ({
      variantOptionIds: variant.variantOptionIds,
      price: Number(variant.price),
      stock: Number(variant.stock),
      isMainVariant: variant.isMainVariant,
      variantDescription: variant.variantDescription || "",
      sku: variant.sku,
    })),
  };

  console.log("Creating product with data:", transformedData);
  return apiService.post(
    "/api/admin/products/variants/create",
    transformedData
  );
};

export const updateProduct = (id, productData) => {
  const transformedData = {
    productName: productData.productName,
    productType: productData.productType,
    description: productData.description,
    additionalInfo: productData.additionalInfo,
    categoryId: productData.categoryId,
    variants: productData.variants.map((variant) => ({
      variantOptionIds: variant.variantOptionIds,
      price: Number(variant.price),
      stock: Number(variant.stock),
      isMainVariant: variant.isMainVariant,
      variantDescription: variant.variantDescription || "",
      sku: variant.sku,
    })),
  };

  console.log("Updating product with data:", transformedData);
  return apiService.put(`/api/admin/products/${id}`, transformedData);
};

export const deleteProduct = (id) =>
  apiService.delete(`/api/admin/products/${id}`);

export const getAllProductTypes = () =>
  apiService.get("/api/admin/products/types");

export const getProductTypeById = (id) =>
  apiService.get(`/api/admin/products/types/${id}`);

export const createProductType = (typeData) =>
  apiService.post("/api/admin/products/types", {
    name: typeData.name,
  });

export const updateProductType = (id, typeData) =>
  apiService.put(`/api/admin/products/types/${id}`, {
    name: typeData.name,
  });

export const deleteProductType = (id) =>
  apiService.delete(`/api/admin/products/types/${id}`);
