import { lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import withSuspense from "./hoc/withSuspense";
import { homeLoader } from "./pages/home/Home";
import { productListLoader } from "./pages/admin/Product/ProductList/ProductList";
import ProtectedRoute from "./pages/protectedRoute";

const Home = lazy(() => import("./pages/home/Home"));
const Shop = lazy(() => import("./pages/shop/Shop"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const BlogDetail = lazy(() => import("./pages/blog/BlogDetail"));
const AdminLogin = lazy(() => import("./pages/admin/AdminLogin/AdminLogin"));
const AdminDashboard = lazy(() =>
  import("./pages/admin/AdminDashboard/AdminDashboard")
);
const CategoryList = lazy(() =>
  import("./pages/admin/Category/CategoryList/CategoryList")
);
const CategoryForm = lazy(() =>
  import("./pages/admin/Category/CategoryForm/CategoryForm")
);
const ProductList = lazy(() =>
  import("./pages/admin/Product/ProductList/ProductList")
);
const ProductForm = lazy(() =>
  import("./pages/admin/Product/ProductForm/ProductForm")
);
const VariantList = lazy(() =>
  import("./pages/admin/Variant/VariantList/VariantList")
);
const VariantForm = lazy(() =>
  import("./pages/admin/Variant/VariantForm/VariantForm")
);
const ProductDetail = lazy(() =>
  import("./pages/admin/Product/ProductDetail/ProductDetail")
);
const ImagesSection = lazy(() => import("./pages/admin/Images/ImagesSection"));

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: withSuspense(Home),
      errorElement: <NotFound />,
      loader: homeLoader,
    },
    {
      path: "/home",
      element: withSuspense(Home),
      loader: homeLoader,
    },
    {
      path: "/shop",
      element: withSuspense(Shop),
    },
    {
      path: "/product/:id",
      element: withSuspense(Product),
    },
    {
      path: "/blog",
      element: withSuspense(Blog),
    },
    {
      path: "/blog-detail",
      element: withSuspense(BlogDetail),
    },
    {
      path: "/about",
      element: withSuspense(About),
    },
    {
      path: "/contact",
      element: withSuspense(Contact),
    },
    {
      path: "/my-account",
      element: withSuspense(MyAccount),
    },
    {
      path: "/login-register",
      element: withSuspense(LoginRegister),
    },
    {
      path: "/cart",
      element: withSuspense(Cart),
    },
    {
      path: "/wishlist",
      element: withSuspense(Wishlist),
    },
    {
      path: "/compare",
      element: withSuspense(Compare),
    },
    {
      path: "/checkout",
      element: withSuspense(Checkout),
    },

    {
      path: "/admin/login",
      element: withSuspense(AdminLogin),
    },
    {
      path: "/admin-dashboard",
      element: <ProtectedRoute>{withSuspense(AdminDashboard)}</ProtectedRoute>,
      children: [
        {
          index: true,
          element: <ProtectedRoute>{withSuspense(ProductList)}</ProtectedRoute>,
          loader: productListLoader,
        },

        {
          path: "products",
          element: <ProtectedRoute>{withSuspense(ProductList)}</ProtectedRoute>,
          loader: productListLoader,
        },
        {
          path: "products/new",
          element: <ProtectedRoute>{withSuspense(ProductForm)}</ProtectedRoute>,
        },
        {
          path: "products/:id/edit",
          element: <ProtectedRoute>{withSuspense(ProductForm)}</ProtectedRoute>,
        },
        {
          path: "products/:id",
          element: (
            <ProtectedRoute>{withSuspense(ProductDetail)}</ProtectedRoute>
          ),
        },

        {
          path: "categories",
          element: (
            <ProtectedRoute>{withSuspense(CategoryList)}</ProtectedRoute>
          ),
        },
        {
          path: "categories/new",
          element: (
            <ProtectedRoute>{withSuspense(CategoryForm)}</ProtectedRoute>
          ),
        },
        {
          path: "categories/:id/edit",
          element: (
            <ProtectedRoute>{withSuspense(CategoryForm)}</ProtectedRoute>
          ),
        },

        {
          path: "variants",
          element: <ProtectedRoute>{withSuspense(VariantList)}</ProtectedRoute>,
        },
        {
          path: "variants/new",
          element: <ProtectedRoute>{withSuspense(VariantForm)}</ProtectedRoute>,
        },
        {
          path: "variants/:id/edit",
          element: <ProtectedRoute>{withSuspense(VariantForm)}</ProtectedRoute>,
        },
        {
          path: "images",
          element: (
            <ProtectedRoute>{withSuspense(ImagesSection)}</ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: "*",
      element: withSuspense(NotFound),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
