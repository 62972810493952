//INFO: in localStorage we can only store strings, so we need to convert objects to strings before storing them and parse them back to objects when retrieving them in getItem function
export const setItemToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error storing ${key} in local storage`, error);
  }
};

export const getItemFromLocalStorage = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Error retrieving ${key} from local storage`, error);
    return null;
  }
};

export const removeItemFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing ${key} from local storage`, error);
  }
};

export const isStored = (key) => {
  return !!localStorage.getItem(key);
};
