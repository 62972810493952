import React, { useEffect } from "react";
import { Link, useLoaderData, useNavigate, redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ProductList.module.css";
import LinkButton from "../../../../components/button/LinkButton";
import { colors } from "../../../../constants/color";
import { selectIsAuthenticated } from "../../../../store/slices/auth-slice";
import {
  fetchProducts,
  removeProduct,
  selectAllProducts,
  selectProductError,
  selectProductLoading,
  selectTotalPages,
  selectTotalItems,
  clearProductError,
} from "../../../../store/slices/product-slice";

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page, size } = useLoaderData();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const products = useSelector(selectAllProducts);
  const loading = useSelector(selectProductLoading);
  const error = useSelector(selectProductError);
  const totalPages = useSelector(selectTotalPages);
  const totalItems = useSelector(selectTotalItems);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/admin/login");
    }
  }, [isAuthenticated, navigate]);

  const handleDelete = async (productId) => {
    if (!window.confirm("Are you sure you want to delete this product?")) {
      return;
    }

    await dispatch(removeProduct({ id: productId, page })).unwrap();

    if (products.length === 1 && page > 1) {
      navigate(`?page=${page - 1}&size=${size}`);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      navigate(`?page=${newPage}&size=${size}`);
      //INFO: Bu yaklaşımın öncekinden neden daha iyi olduğunu anlatan maddeler:
      // URL always shows current page
      // Can bookmark specific pages
      // Browser back/forward buttons work naturally
      // Refreshing keeps you on the same page
      // State is more predictable
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loading}>Loading products...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.error}>
          Error: {error}
          <button
            onClick={() => {
              dispatch(clearProductError());
              dispatch(fetchProducts({ page: page - 1, size }));
            }}
            className={styles.retryButton}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Products</h1>
        <Link to="/admin-dashboard/products/new" className={styles.addButton}>
          Add New Product
        </Link>
      </div>

      {products.length === 0 ? (
        <div className={styles.noProducts}>
          No products found. Click "Add New Product" to create one.
        </div>
      ) : (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeader}>Product Name</th>
                <th className={styles.tableHeader}>Category</th>
                <th className={styles.tableHeader}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.productId} className={styles.tableRow}>
                  <td className={styles.tableCell}>{product.productName}</td>
                  <td className={styles.tableCell}>
                    {product.categoryName || "N/A"}
                  </td>
                  <td className={styles.tableCell}>
                    <div className={styles.actionButtons}>
                      <LinkButton
                        to={`/admin-dashboard/products/${product.productId}`}
                        text="View"
                        type={colors.primary}
                      />
                      <LinkButton
                        to={`/admin-dashboard/products/${product.productId}/edit`}
                        text="Edit"
                        type={colors.warning}
                      />
                      <LinkButton
                        to={`/admin-dashboard/images?productId=${product.productId}`}
                        text="Images"
                        type={colors.info}
                      />
                      <button
                        onClick={() => handleDelete(product.productId)}
                        className={`${styles.deleteButton} ${styles.actionButton}`}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.pagination}>
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className={styles.paginationButton}
            >
              Previous
            </button>
            <span className={styles.pageInfo}>
              Page {page} of {totalPages} ({totalItems} items)
            </span>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= totalPages}
              className={styles.paginationButton}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export async function productListLoader({ request }) {
  const url = new URL(request.url);
  const page = parseInt(url.searchParams.get("page")) || 1;
  const size = 15;

  const { store } = await import("../../../../store/store");
  await store.dispatch(fetchProducts({ page: page - 1, size }));

  return { page, size };
}

export default ProductList;
