import { Link } from "react-router-dom";
import styles from "./LinkButton.module.css";

function LinkButton({ to, text, type }) {
  return (
    <Link
      to={to}
      className={styles.linkButton}
      style={{ backgroundColor: type }}
    >
      {text}
    </Link>
  );
}

export default LinkButton;
