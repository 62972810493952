import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminLogin, adminRefresh } from "../../api/authEndpoints";
import {
  getItemFromLocalStorage,
  isStored,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from "../../utils/localStorageUtils";

const AUTH_TOKEN_KEY = "auth_token";
const REFRESH_TOKEN_KEY = "refresh_token";

const initialState = {
  user: null,
  token: getItemFromLocalStorage(AUTH_TOKEN_KEY),
  loading: false,
  error: null,
  isAuthenticated: isStored(AUTH_TOKEN_KEY),
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, thunkAPI) => {
    try {
      const response = await adminLogin(credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, thunkAPI) => {
    try {
      const response = await adminRefresh();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      removeItemFromLocalStorage(AUTH_TOKEN_KEY);
    },
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        setItemToLocalStorage(AUTH_TOKEN_KEY, action.payload.body.accessToken);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || "Lütfen giriş bilgilerinizi kontrol edin.";
        state.isAuthenticated = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        setItemToLocalStorage(
          REFRESH_TOKEN_KEY,
          action.payload.data.refreshToken
        );
      })
      .addCase(refreshToken.rejected, (state) => {
        state.isAuthenticated = false;
        removeItemFromLocalStorage(AUTH_TOKEN_KEY);
      });
  },
});

export const { setToken, clearToken, logout, clearError } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectAuthLoading = (state) => state.auth.loading;
export const selectAuthError = (state) => state.auth.error;
export const selectAuthToken = (state) => state.auth.token;

export default authSlice.reducer;
